import Vue from 'vue'
import App from './App.vue'
import css from '../node_modules/normalize.css'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.use(css)
Vue.use(ViewUI, {
  transfer: true,
  size: 'large',
  capture: false,
  select: {
    arrow: 'md-arrow-dropdown',
    arrowSize: 20
  }
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
